
import * as API_Common from "@/api/common.js";
import myBMap from "../utils/myBMap"; // 引入百度地图
import AMap from "AMap"; //在页面中引入高德地图
export const mapPosition = {
    methods: {
        // 获取定位
        checkPosition() {
            // 生产环境(使用高德定位)
            if (this.env === "production") {
                this.initAMap();
            } else {
                // 非生产环境(使用百度定位)
                this.initBMap();
            }
        },

        // 初始化高德地图定位
        initAMap() {
            const that = this;
            AMap.plugin("AMap.Geolocation", function () {
                let geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true, // 是否使用高精度定位，默认：true
                    timeout: 5000, // 设置定位超时时间，默认：无穷大
                });

                // 获取用户当前的精确位置信息(经纬度)
                geolocation.getCurrentPosition(function (status, result) {
                    // 如果成功
                    if (status === "complete") {
                        // result.position为地址的经纬度(数组[x, y])
                        let longitudeAndLatitude = result.position;
                        // 逆向地理反查
                        that.getLngLatLocation(longitudeAndLatitude);
                    } else {
                        that.$toast.fail("定位失败，请手动选择城市");
                        that.manualPosition();
                    }
                });
            });
        },

        // 逆向地理编码查询地理位置详细信息
        getLngLatLocation(longitudeAndLatitude) {
            let that = this;

            AMap.plugin("AMap.Geocoder", function () {
                let geocoder = new AMap.Geocoder({
                    // 是否批量查询batch=true为批量查询,batch=false为单点查询，batch=false时即使传入多个点也只返回第一个点结果
                    // 配置信息详见https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address/#m_AMap.Geocoder
                    batch: false,
                });

                geocoder.getAddress(longitudeAndLatitude, function (status, data) {
                    // 逆向地理反查成功
                    if (status === "complete" && data.info === "OK") {
                        that.positionStatus = true;
                        let addComp = data.regeocode.addressComponent;

                        that.stepLineMarginL = that.stepLineMarginL + 5; // 如果定位城市定位成功则进度加5%
                        that.address.province = addComp.province; //返回当前省份
                        that.address.city = addComp.city; //返回当前城市
                        that.form.gpsCityName = `${that.address.province} ${that.address.city}`;
                    } else {
                        // 逆向地理反查失败
                        that.$toast.fail("定位失败，请手动选择城市");
                        that.manualPosition();
                    }
                });
            });
        },

        // 初始化百度地图定位
        initBMap() {
            myBMap.init().then((BMap) => {
                let that = this;
                let geolocation = new BMap.Geolocation();
                let gc = new BMap.Geocoder();

                geolocation.enableSDKLocation(); // 开启SDK辅助定位
                // 创建百度地理位置实例，代替 navigator.geolocation
                geolocation.getCurrentPosition(
                    function (r) {
                        if (r) {
                            /*
                            关于this.getStatus()返回状态码示例说明
                            BMAP_STATUS_SUCCESS 检索成功。对应数值“0”
                            BMAP_STATUS_CITY_LIST 城市列表。对应数值“1”
                            BMAP_STATUS_UNKNOWN_LOCATION 位置结果未知。对应数值“2”
                            BMAP_STATUS_UNKNOWN_ROUTE 导航结果未知。对应数值“3”
                            BMAP_STATUS_INVALID_KEY 非法密钥。对应数值“4”
                            BMAP_STATUS_INVALID_REQUEST 非法请求。对应数值“5”
                            BMAP_STATUS_PERMISSION_DENIED 没有权限。对应数值“6”
                            BMAP_STATUS_SERVICE_UNAVAILABLE 服务不可用。对应数值“7”
                            BMAP_STATUS_TIMEOUT 超时。对应数值“8”
                          */
                            if (this.getStatus() === 0) {
                                let pt = r.point;
                                that.positionStatus = true;

                                // 逆地址解析
                                gc.getLocation(pt, function (rs) {
                                    let addComp = rs.addressComponents;

                                    // 如果没获取到市信息也当成定位失败处理(此时拿百度地图返回的省从集合中筛选出对应的省ID，去查询城市列表)
                                    if (!addComp.city) {
                                        that.positionCityStatus = true;
                                        r.address.city = "";

                                        API_Common.getRegions(0).then((res) => {
                                            if (200 === res.code) {
                                                that.provinceArr = res.data.filter(
                                                    (i) => i.localName === r.address.province
                                                );
                                                that.manualPosition();
                                            }
                                        });
                                    } else {
                                        that.stepLineMarginL = that.stepLineMarginL + 5; // 如果定位城市定位成功则进度加5%
                                        that.address.province = addComp.province; //返回当前省份
                                        that.address.city = addComp.city; //返回当前城市
                                        that.form.gpsCityName = `${that.address.province} ${that.address.city}`;
                                    }
                                });
                            } else {
                                that.$toast.fail("定位失败，请手动选择城市");
                                that.manualPosition();
                            }
                        }
                    },
                    function () {
                        that.$toast.fail("定位失败，请手动选择城市");
                        that.manualPosition();
                    },
                    { province: "baidu" }
                );
            });
        },

        // 手动选择定位
        manualPosition() {
            this.positionStatus = false;
        },

        // 获取省初始化默认传递0默认值省对应下面的城市
        getArea(parentId, index) {
            API_Common.getRegions(parentId).then((res) => {
                if (200 === res.code) {
                    this.areaList[index].values = [{ localName: "请选择" }, ...res.data];
                    this.areaList = [...this.areaList]; //更新areaList
                    this.loading = false;
                } else {
                    this.$toast.fail("获取省份失败");
                }
            });
        },

        // 获取省(这个针对百度地图没有返回城市信息的时候，只允许用户操作对应省份下面的城市数据)
        getAreaNotCity(arr) {
            this.areaList[0].values = [{ localName: "请选择" }, ...arr];
            this.areaList = [...this.areaList]; //更新areaList
        },

        // 选择省市下拉选的事件监听
        onCityChange(picker, values, index) {
            if (values[index].localName === "请选择" && index !== 1) {
                this.areaList[1].values = [{ localName: "请选择" }];
            } else {
                if (!index) {
                    this.getArea(values[0].id, index + 1); //传参 参数为上层选择的地区的id
                }
            }
        },

        // 地址确认按钮点击
        onAreaConfirm(picker) {
            if (
                picker[0].localName === "请选择" ||
                picker[1].localName === "请选择"
            ) {
                this.$toast.fail("请选择完整的省市");
                return false;
            } else {
                this.form.gpsCityName = `${picker[0].localName} ${picker[1].localName}`;
                this.form.provinceId = picker[0].id;
                this.form.cityId = picker[1].id;
                this.showPickerCity = false;

                // 手动选择定位顶部进度增加5%
                if (!this.isSelectAddr) {
                    // 如果有默认定位，则先把进度减去5%
                    if (this.positionStatus) {
                        this.stepLineMarginL = this.stepLineMarginL - 5; // 如果定位城市定位成功则进度加5%
                    }
                    this.stepLineMarginL = this.stepLineMarginL + 5; // 如果定位城市定位成功则进度加5%
                    this.positionStatus = false;
                }

                this.isSelectAddr = true;
            }
        },

        // 监听定位城市栏点击
        handleClickPosition() {
            // 如果百度地图没返回城市数据那么只允许用户去筛选定位出来的省份下面的城市，不允许用户操作除定位省以外的省份
            if (this.positionCityStatus) {
                this.getAreaNotCity(this.provinceArr, 0);
            } else {
                this.getArea(0, 0);
            }
            this.showPickerCity = true;
        },
    }
}