
export const applyCommon = {
    data() {
        return {
            formItemData: {
                credit: [
                  // 芝麻分集合
                  { dictValue: "700以上", dictLabel: "700以上", active: false },
                  { dictValue: "650-700", dictLabel: "650-700", active: false },
                  { dictValue: "600-650", dictLabel: "600-650", active: false },
                ],
                house: [
                  // 房产集合
                  { dictValue: 1, dictLabel: "有房产", active: false },
                  { dictValue: 0, dictLabel: "无房产", active: false },
                ],
                car: [
                  // 车产集合
                  { dictValue: 1, dictLabel: "有车产", active: false },
                  { dictValue: 0, dictLabel: "无车产", active: false },
                ],
                accumulation: [
                  // 公积金集合
                  { dictValue: 1, dictLabel: "12个月以上", active: false },
                  { dictValue: 1, dictLabel: "12个月以下", active: false },
                  { dictValue: 0, dictLabel: "无公积金", active: false },
                ],
                social: [
                  // 社保集合
                  { dictValue: 1, dictLabel: "12个月以上", active: false },
                  { dictValue: 1, dictLabel: "12个月以下", active: false },
                  { dictValue: 0, dictLabel: "无社保", active: false },
                ],
                insurance: [
                  // 保险集合
                  { dictValue: 1, dictLabel: "12个月以上", active: false },
                  { dictValue: 1, dictLabel: "12个月以下", active: false },
                  { dictValue: 0, dictLabel: "无保险", active: false },
                ],
                profession: [
                  // 职业集合
                  { dictValue: 1, dictLabel: "企业主", active: false },
                  { dictValue: 0, dictLabel: "上班族", active: false },
                  { dictValue: 0, dictLabel: "公务员/国企", active: false },
                  { dictValue: 0, dictLabel: "自由职业", active: false },
                ],
                antCreditPay: [
                  // 蚂蚁花呗集合
                  { dictValue: 1, dictLabel: "有花呗", active: false },
                  { dictValue: 0, dictLabel: "无花呗", active: false },
                ],
                jdIous: [
                  // 京东白条集合
                  { dictValue: 1, dictLabel: "有白条", active: false },
                  { dictValue: 0, dictLabel: "无白条", active: false },
                ],
            },
            regTest: {
                // 正则校验
                regIdcard:
                    /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 身份证号码正则校验
            },           
        }
    },
    methods: {
        // 选择芝麻分
        handleChooseCreditCredit(index) {
            this.formItemData.credit.map((item) => {
            item.active = false;
            });
            this.formItemData.credit[index].active = true;
            this.form.credit = this.formItemData.credit[index].dictValue;
            this.assetFromJS.credit = this.formItemData.credit[index].dictValue;
            this.showNextActive(this.assetFromJS.house, "2");
            this.$forceUpdate();
        },

        // 选择房产
        handleChooseHouse(index) {
            this.formItemData.house.map((item) => {
            item.active = false;
            });
            this.formItemData.house[index].active = true;
            this.assetFromJS.house = this.formItemData.house[index].dictLabel;
            this.showNextActive(this.assetFromJS.car, "3");
            this.$forceUpdate();
        },

        // 选择车产
        handleChooseCar(index) {
            this.formItemData.car.map((item) => {
                item.active = false;
            });
            this.formItemData.car[index].active = true;
            this.assetFromJS.car = this.formItemData.car[index].dictLabel;
            this.showNextActive(this.assetFromJS.accumulation, "4");
            this.$forceUpdate();
        },

        // 选择公积金
        handleChooseAccumulation(index) {
            this.formItemData.accumulation.map((item) => {
                item.active = false;
            });
            this.formItemData.accumulation[index].active = true;
            this.assetFromJS.accumulation =
            this.formItemData.accumulation[index].dictLabel;
            this.showNextActive(this.assetFromJS.social, "5");
            this.$forceUpdate();
        },

        // 选择社保
        handleChooseSocial(index) {
            this.formItemData.social.map((item) => {
                item.active = false;
            });
            this.formItemData.social[index].active = true;
            this.assetFromJS.social = this.formItemData.social[index].dictLabel;
            this.showNextActive(this.assetFromJS.insurance, "6");
            this.$forceUpdate();
        },

        // 选择商业保险
        handleChooseInsurance(index) {
            this.formItemData.insurance.map((item) => {
                item.active = false;
            });
            this.formItemData.insurance[index].active = true;
            this.assetFromJS.insurance = this.formItemData.insurance[index].dictLabel;
            this.showNextActive(this.assetFromJS.profession, "7");
            this.$forceUpdate();
        },

        // 选择职业
        handleChooseProfession(index) {
            this.formItemData.profession.map((item) => {
                item.active = false;
            });
            this.formItemData.profession[index].active = true;
            this.assetFromJS.profession =
            this.formItemData.profession[index].dictLabel;
            this.showNextActive(this.assetFromJS.jdIous, "8");
            this.$forceUpdate();
        },

        // 选择京东白条
        handleChooseCreditJdIous(index) {
            this.formItemData.jdIous.map((item) => {
                item.active = false;
            });
            this.formItemData.jdIous[index].active = true;
            this.form.jdIous = this.formItemData.jdIous[index].dictValue;
            this.assetFromJS.jdIous = this.formItemData.jdIous[index].dictLabel;
            this.showNextActive(this.assetFromJS.antCreditPay, "9");
            this.$forceUpdate();
        },

        // 选择蚂蚁花呗
        handleChooseCreditAntCreditPay(index) {
            this.formItemData.antCreditPay.map((item) => {
                item.active = false;
            });
            this.formItemData.antCreditPay[index].active = true;
            this.form.antCreditPay = this.formItemData.antCreditPay[index].dictValue;
            this.assetFromJS.antCreditPay =
            this.formItemData.antCreditPay[index].dictLabel;
            this.activeNames = ["10"];
            this.$forceUpdate();
        },

        // 显示下个节点
        showNextActive(dictLabel, activeName) {
            if (!dictLabel) {
                // 当前值不为空
                this.activeNames = [activeName];
            } else {
                // 当前值不为空
                this.activeNames = ["100"];
            }
        },  
        
        // 设置资产信息集合
        setAssetArray() {
            this.form.assets = [];
            this.pushAssetsVal(this.formItemData.house, this.assetFromJS.house, 1);
            this.pushAssetsVal(this.formItemData.car, this.assetFromJS.car, 2);
            this.pushAssetsVal(
                this.formItemData.accumulation,
                this.assetFromJS.accumulation,
                3
            );
            this.pushAssetsVal(this.formItemData.social, this.assetFromJS.social, 4);
            this.pushAssetsVal(this.formItemData.insurance, this.assetFromJS.insurance, 5);
            this.pushAssetsVal(this.formItemData.profession, this.assetFromJS.profession, 6);
        },

        // 设置资产信息的值
        pushAssetsVal(assetArr, dictLable, assetVal) {
            let assets = assetArr.filter((item) => item.dictLabel === dictLable);
            if (assets.length === 0) {
                return;
            }
            let dictValue = assets[0].dictValue;
            if (dictValue === 0) {
                return;
            }
            this.form.assets.push(assetVal);
        }, 
        
        
        // 给浏览器的访问记录添加一条此页面的访问记录
        addHistory() {
            window.history.pushState(null, null, document.URL);
            window.addEventListener("popstate", this.openReturnPop, false);
        },

        // 打开回退弹窗
        openReturnPop() {
            this.returnShow = true;
            var equipmentSystem = this.mixinClientBrowser();
            if (equipmentSystem === "android") {
                try {
                //安卓直接使用popstate监听返回键无效，需要加这行代码
                window.tbs_bridge.nativeExec("network", "type", 0, null);
                } catch (e) {
                console.log(e);
                }
            }
        },
        
        // 判断是ios 还是安卓
        mixinClientBrowser() {
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                //ios
                return "ios";
            } else if (/(Android)/i.test(navigator.userAgent)) {
                //android
                return "android";
            }
        }               
    }
}